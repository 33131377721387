import React, { useEffect, useState } from 'react'
import { Row, Col, Skeleton, Button, Cascader, List, Drawer, Modal, Card, Empty, Layout, Grid, Slider, Collapse } from 'antd'
import { CloseOutlined, LikeOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons'

import './App.css';


const PecsCard = ({ card, active = false, onClick, style = {} }) => {
  if (!card) return 'Not a card'

  return <Card
    title={card.label}
    onClick={e => onClick && onClick(e)}
    hoverable
    headStyle={{
      backgroundColor: active ? '#00f7' : '#fff',
      textAlign: 'center',
    }}
    bodyStyle={{
      backgroundColor: active ? '#00f7' : '#fff',
      padding: '5px',
      // aspectRatio: '1',
    }}
  >
    {card.url ?
      <img src={card.url} alt={card.label}
        style={{
          width: '100%',
          maxHeight: '60vh',
          objectFit: 'contain',
        }}
      /> :
      <Empty description={false} />
    }
  </Card>
}


const parseItems = items => {
  const getLabel = property => property.title.map(item => item.text.content).join(' ')
  const getFileUrl = property => property.files.length > 0 && property.files[0].file?.url
  const getTags = property => property.multi_select.map(tag => tag.name)

  const cards = items.map(item => ({
    key: item.id,
    label: getLabel(item.properties.Name),
    url: getFileUrl(item.properties.Image),
    tags: getTags(item.properties.Tags),
  }))
  cards.sort((a, b) => a.label.localeCompare(b.label))

  const tags = [...new Set(cards.flatMap(card => card.tags))]
  tags.sort()

  return { cards, tags }
}


const App = () => {
  const bp = Grid.useBreakpoint()
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState([])
  const [tags, setTags] = useState([])
  const [columns, setColumns] = useState(bp.xs ? 1 : 2)
  const [selectedCardKeys, setSelectedCardKeys] = useState([null])
  const [activeCard, setActiveCard] = useState()
  const [showDrawer, setShowDrawer] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetch('/.netlify/functions/notion')
      .then(response => response.json())
      .then(data => {
        const { cards, tags } = parseItems(data)
        setCards(cards)
        setTags(tags)
      })
      .finally(() => setLoading(false))
  }, [])

  const selectedCards = selectedCardKeys
    .filter(cardKey => !!cardKey)
    .map(cardKey => cards.find(card => card.key === cardKey[1]))

  return <Layout style={{ maxHeight: '100vh' }}>
    <Layout.Header style={{ backgroundColor: '#eee' }}>
      <Row justify="space-between">
        <Col><LikeOutlined /> PECS</Col>
        <Col><MenuOutlined onClick={() => setShowDrawer(true)} /></Col>
      </Row>
      <Drawer
      title="Edit cards"
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      footer={<Button type="primary" onClick={() => setShowDrawer(false)}>Finish</Button>}
    >
      <Skeleton loading={loading}>
        <Collapse defaultActiveKey={['cards']} ghost>
          <Collapse.Panel header="Cards" key="cards">
            <List
              dataSource={selectedCardKeys.map((cardKey, i) => ({ key: i, value: cardKey }))}
              renderItem={cardKeyItem => <Row gutter={8} style={{ marginBottom: '8px' }}>
                <Col span={20}>
                  <Cascader
                    style={{ width: '100%' }}
                    value={cardKeyItem.value}
                    options={tags.map(tag => ({
                      label: tag,
                      value: tag,
                      children: cards.filter(card => card.tags.includes(tag)).map(card => ({ label: card.label, value: card.key }))
                    }))}
                    onChange={value => {
                      selectedCardKeys[cardKeyItem.key] = value
                      setSelectedCardKeys([...selectedCardKeys])
                    }}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    onClick={() => {
                      selectedCardKeys.splice(cardKeyItem.key, 1)
                      setSelectedCardKeys([...selectedCardKeys])
                    }}
                  />
                </Col>
              </Row>}
              footer={<Button icon={<PlusOutlined />} onClick={() => setSelectedCardKeys([...selectedCardKeys, null])}>Add card</Button>}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Columns" key="columns">
            <Slider
              min={1} max={5}
              value={columns}
              onChange={value => setColumns(value)}
              marks={[0, 1, 2, 3, 4, 5]}
            />
          </Collapse.Panel>
        </Collapse>
      </Skeleton>
    </Drawer>
    </Layout.Header>
    <Layout.Content style={{ backgroundColor: '#fff', padding: '10px' }}>
      <Skeleton loading={loading}>
        <Row gutter={[24, 24]} justify="space-evenly">
          {selectedCards.map(card => <Col key={card.key} span={24 / Math.min(columns, selectedCards.length)} style={{ aspectRatio: null }}>
            <PecsCard
              card={card}
              active={card.key === activeCard?.key}
              onClick={e => {
                  card.key === activeCard?.key ? setActiveCard() : setActiveCard(card)
                  e.stopPropagation()
              }}
            />
          </Col>)}
        </Row>
        <Modal
          open={false && !!activeCard}
          onOk={() => setActiveCard()}
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <PecsCard card={activeCard} active style={{ width: '100%' }} />
        </Modal>
      </Skeleton>
    </Layout.Content>
  </Layout>
}


export default App;
